<template>
  <div>
    <a-card style="background-color: #fff;">
      <a-row>
        <a-button @click="addBtn" type="primary" v-if="hasPermission('SAVE_CONTACE') && isEdit">新增联系人</a-button>
      </a-row>
      <!-- 创建 -->
      <a-modal title="新增联系人" :visible="visible" :confirm-loading="confirmLoading" :maskClosable="false" @ok="handleOk"
        :footer="null" @cancel="visible = false; prov1 = []; prov2 = []; prov3 = []" :width="1100">
        <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">

          <a-form-model-item ref="name" prop="name" label="联系人" :labelCol="{ span: 4 }"
            :wrapperCol="{ span: 18, offset: 1 }">
            <div style="display: flex;position: relative;">
              <a-input v-model="form.name" placeholder="请输入联系人" @blur="() => { $refs.name.onFieldBlur(); }" />
            </div>
          </a-form-model-item>
          <a-form-model-item label="联系人角色" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
            <a-select v-model="form.role" style="width: 100%" placeholder="请选择联系人角色">
              <a-select-option :value="index + 1" :label="item.name" v-for=" (item, index) in roleList" :key="index">
                {{ item }} 
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item label="性别" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
            <a-select v-model="form.sex" style="width: 100%" placeholder="请选择联系人性别">
              <a-select-option :value="index + 1" :label="item.name" v-for=" (item, index) in sexList" :key="index">
                {{ item }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item label="生日" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
            <div style="display: flex;position: relative;">
              <a-date-picker style="width: 100%;" @change="birthdayChange" placeholder="请选择联系人生日" />
            </div>
          </a-form-model-item>

          <a-form-model-item label="联系人联系方式" required :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
            <div style="display: flex;">
              <div style="position: relative;flex: 1;">
                <div v-for="(item, index) in phone" :key="index" style="display: flex;margin-bottom: 4px;">
                  <a-select :disabled="index == 0" label-in-value :default-value="{ key: '手机号' }"
                    style="width: 120px; margin: 0 4px;" @change="phoneChange($event, index)">
                    <a-select-option value="1">手机号</a-select-option>
                    <a-select-option value="2">座机号</a-select-option>
                    <a-select-option value="3">邮箱</a-select-option>
                    <a-select-option value="4">微信号</a-select-option>
                    <a-select-option value="5">QQ号</a-select-option>
                    <a-select-option value="6">其他</a-select-option>
                  </a-select>
                  <a-input v-if="item.type == 1" placeholder="请输入联系人手机号" v-model="item.contactInformation" />
                  <a-input v-if="item.type == 2" placeholder="请输入联系人座机号" v-model="item.contactInformation" />
                  <a-input v-if="item.type == 3" placeholder="请输入联系人邮箱" v-model="item.contactInformation" />
                  <a-input v-if="item.type == 4" placeholder="请输入联系人微信号" v-model="item.contactInformation" />
                  <a-input v-if="item.type == 5" placeholder="请输入联系人QQ号" v-model="item.contactInformation" />
                  <a-input v-if="item.type == 6" placeholder="请输入其他联系方式" v-model="item.contactInformation" />
                  <a-button v-if="index > 0" type="error" @click="delIpt(0, index)">删除</a-button>
                </div>
              </div>
              <a-button type="primary" style="margin-left: 10px;" @click="addIpt(0)">添加</a-button>
            </div>
            <div style="display: flex;margin-top: 10px;">
              <div style="position: relative;flex: 1;">
                <div v-for="(item, index) in wechat" :key="index" style="display: flex;margin-bottom: 4px;">
                  <a-select :disabled="index == 0" label-in-value :default-value="{ key: '微信号' }"
                    style="width: 120px; margin: 0 4px;" @change="weChatChange($event, index)">
                    <a-select-option value="1">手机号</a-select-option>
                    <a-select-option value="2">座机号</a-select-option>
                    <a-select-option value="3">邮箱</a-select-option>
                    <a-select-option value="4">微信号</a-select-option>
                    <a-select-option value="5">QQ号</a-select-option>
                    <a-select-option value="6">其他</a-select-option>
                  </a-select>
                  <a-input v-if="item.type == 1" placeholder="请输入联系人手机号" v-model="item.contactInformation" />
                  <a-input v-if="item.type == 2" placeholder="请输入联系人座机号" v-model="item.contactInformation" />
                  <a-input v-if="item.type == 3" placeholder="请输入联系人邮箱" v-model="item.contactInformation" />
                  <a-input v-if="item.type == 4" placeholder="请输入联系人微信号" v-model="item.contactInformation" />
                  <a-input v-if="item.type == 5" placeholder="请输入联系人QQ号" v-model="item.contactInformation" />
                  <a-input v-if="item.type == 6" placeholder="请输入其他联系方式" v-model="item.contactInformation" />
                  <a-button v-if="index > 0" type="error" @click="delIpt(1, index)">删除</a-button>
                </div>
              </div>
              <a-button type="primary" style="margin-left: 10px;" @click="addIpt(1)">添加</a-button>
            </div>

            <div style="display: flex;margin-top: 10px;">
              <div style="position: relative;flex: 1;">
                <div v-for="(item, index) in zuoji" :key="index" style="display: flex;margin-bottom: 4px;">
                  <a-select :disabled="index == 0" label-in-value :default-value="{ key: '座机号' }"
                    style="width: 120px; margin: 0 4px;" @change="zuojiChange($event, index)">
                    <a-select-option value="1">手机号</a-select-option>
                    <a-select-option value="2">座机号</a-select-option>
                    <a-select-option value="3">邮箱</a-select-option>
                    <a-select-option value="4">微信号</a-select-option>
                    <a-select-option value="5">QQ号</a-select-option>
                    <a-select-option value="6">其他</a-select-option>
                  </a-select>
                  <a-input v-if="item.type == 1" placeholder="请输入联系人手机号" v-model="item.contactInformation" />
                  <a-input v-if="item.type == 2" placeholder="请输入联系人座机号" v-model="item.contactInformation" />
                  <a-input v-if="item.type == 3" placeholder="请输入联系人邮箱" v-model="item.contactInformation" />
                  <a-input v-if="item.type == 4" placeholder="请输入联系人微信号" v-model="item.contactInformation" />
                  <a-input v-if="item.type == 5" placeholder="请输入联系人QQ号" v-model="item.contactInformation" />
                  <a-input v-if="item.type == 6" placeholder="请输入其他联系方式" v-model="item.contactInformation" />
                  <a-button v-if="index > 0" type="error" @click="delIpt(2, index)">删除</a-button>
                </div>
              </div>
              <a-button type="primary" style="margin-left: 10px;" @click="addIpt(2)">添加</a-button>
            </div>
          </a-form-model-item>

          <a-form-model-item label="联系人收货地址" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
            <div style="display: flex;margin-bottom: 10px;" v-for="(item, i) in sfaContactAddressList" :key="i">
              <a-input v-model="item.consignee" placeholder="请输入收货人" />
              <a-input v-model="item.mobile" placeholder="收货人手机号" />
              <a-select @change="provChange($event, i)" :index="i" placeholder="请选择省" v-model="item.provinceName">
                <a-select-option :value="item.id" :label="item.name" v-for="(item, index) in prov" :key="index">{{
                  item.name
                }}</a-select-option>
              </a-select>
              <a-select @change="cityChange($event, i)" :index="i" placeholder="请选择市" v-model="item.cityName">
                <a-select-option :value="item.id" :label="item.name" v-for="(item, index) in city" :key="index">{{
                  item.name
                }}</a-select-option>
              </a-select>
              <a-select @change="areaChange($event, i)" :index="i" placeholder="请选择区" v-model="prov3">
                <a-select-option :value="item.id" :label="item.name" v-for="(item, index) in area" :key="index">{{
                  item.name
                }}</a-select-option>
              </a-select>
              <a-input v-model="item.detailedAddress" placeholder="小区门牌号" />
              <a-button v-if="i == 0" type="primary" style="margin-left: 10px;" @click="addAddress(0, i)">添加</a-button>
              <a-button v-else style="margin-left: 10px;" @click="addAddress(1, i)">删除</a-button>
            </div>
          </a-form-model-item>

        </a-form-model>

        <div style="display: flex;justify-content: center;">
          <a-button type="primary" @click="handleOk" style="margin-right: 10px;">保存</a-button>
          <!-- <a-button type="primary" @click="ToDetail">立即选品</a-button> -->
        </div>
      </a-modal>

      <!-- 修改 -->
      <a-modal title="编辑联系人" :visible="EditVisible" :confirm-loading="confirmLoading" :maskClosable="false"
        @ok="handleEdit" :footer="null" @cancel="EditVisible = false" :width="1100">
        <a-form-model ref="ruleForm" :model="editObj" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">

          <a-form-model-item ref="name" prop="name" label="联系人" :labelCol="{ span: 4 }"
            :wrapperCol="{ span: 18, offset: 1 }">
            <div style="display: flex;position: relative;">
              <a-input v-model="editObj.name" placeholder="请输入联系人" />
            </div>
          </a-form-model-item>
          <a-form-model-item label="联系人角色" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
            <a-select v-model="editObj.role" style="width: 100%" placeholder="请选择联系人角色">
              <a-select-option :value="index + 1" :label="item.name" v-for=" (item, index) in roleList" :key="index">
                {{ item }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item label="性别" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
            <a-select v-model="editObj.sex" style="width: 100%" placeholder="请选择联系人性别">
              <a-select-option :value="index + 1" :label="item.name" v-for=" (item, index) in sexList" :key="index">
                {{ item }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item label="生日" :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
            <div style="display: flex;position: relative;" v-if="editObj.birthday">
              <a-date-picker v-model="editObj.birthday" style="width: 100%;" @change="birthdayEdit"
                placeholder="请选择联系人生日" />
            </div>

            <div style="display: flex;position: relative;" v-else>
              <a-date-picker style="width: 100%;" @change="birthdayEdit" placeholder="请选择联系人生日" />
            </div>
          </a-form-model-item>


          <a-form-model-item label="联系人联系方式" required :labelCol="{ span: 4 }" :wrapperCol="{ span: 18, offset: 1 }">
            <div style="display: flex;">
              <div style="position: relative;flex: 1;">
                <div v-for="(item, index) in zuoji" :key="index" style="display: flex;margin-bottom: 4px;">
                  <a-select label-in-value
                    :default-value="{ key: item.type == 1 ? '手机号' : item.type == 2 ? '座机号' : item.type == 3 ? '邮箱' : item.type == 4 ? '微信号' : item.type == 5 ? 'QQ号' : '其他' }"
                    style="width: 120px; margin: 0 4px;" @change="phoneTypeChange($event, index)" :disabled="item.isNoEdit">
                    <a-select-option value="1">手机号</a-select-option>
                    <a-select-option value="2">座机号</a-select-option>
                    <a-select-option value="3">邮箱</a-select-option>
                    <a-select-option value="4">微信号</a-select-option>
                    <a-select-option value="5">QQ号</a-select-option>
                    <a-select-option value="6">其他</a-select-option>
                  </a-select>
                  <a-input :disabled="item.isNoEdit" v-if="item.type == 1" placeholder="请输入联系人手机号" v-model="item.contactInformation" />
                  <a-input :disabled="item.isNoEdit" v-if="item.type == 2" placeholder="请输入联系人座机号" v-model="item.contactInformation" />
                  <a-input :disabled="item.isNoEdit" v-if="item.type == 3" placeholder="请输入联系人邮箱" v-model="item.contactInformation" />
                  <a-input :disabled="item.isNoEdit" v-if="item.type == 4" placeholder="请输入联系人微信号" v-model="item.contactInformation" />
                  <a-input :disabled="item.isNoEdit" v-if="item.type == 5" placeholder="请输入联系人QQ号" v-model="item.contactInformation" />
                  <a-input :disabled="item.isNoEdit" v-if="item.type == 6" placeholder="请输入其他联系方式" v-model="item.contactInformation" />
                  <a-button :disabled="item.isNoEdit" @click="delPhone(index)" type="error">删除</a-button>
                </div>
              </div>
              <a-button type="primary" style="margin-left: 10px;" @click="addPhone()">添加</a-button>
            </div>
          </a-form-model-item>

        </a-form-model>

        <div style="display: flex;justify-content: center;">
          <a-button type="primary" @click="handleEdit" style="margin-right: 10px;">保存</a-button>
          <!-- <a-button type="primary" @click="ToDetail">立即选品</a-button> -->
        </div>
      </a-modal>
      <div style="margin-top: 20px;">
        <a-table :columns="columns" bordered :dataSource="dataSource" :loading="tabLoading" :pagination="false"
          :scroll="{ x: 1500 }">
          <template slot="birthday" slot-scope="record">
            <span v-if="record">
              {{ formatDate(record) }}
            </span>
            <span v-else>
              -
            </span>
          </template>
          <template slot="phone" slot-scope="record">
            <a-popover>
              <template slot="content">
                {{ record }}
              </template>
              {{ record }}
            </a-popover>
          </template>

          <template slot="landline" slot-scope="record">
            <a-popover>
              <template slot="content">
                {{ record }}
              </template>
              {{ record }}
            </a-popover>
          </template>

          <template slot="mailbox" slot-scope="record">
            <a-popover>
              <template slot="content">
                {{ record }}
              </template>
              {{ record }}
            </a-popover>
          </template>

          <template slot="wechat" slot-scope="record">
            <a-popover>
              <template slot="content">
                {{ record }}
              </template>
              {{ record }}
            </a-popover>
          </template>

          <template slot="action" slot-scope="record">
            <div >
              <div>
                <a @click="editChange(record)" v-if="hasPermission('UPDATE_CONTACT') && isEdit">编辑</a>
                <a @click="addressChange(record.id)" style="margin:0 10px;"
                  v-if="hasPermission('CONTACTADDRESS_LIST') && isShowAddress">收货地址</a>
                <!-- <a-popconfirm style="cursor: pointer;" title="您确定要删除吗？" ok-text="是" cancel-text="否"
                  @confirm="delConfirm(record)" v-if="hasPermission('DEL_CONTACE')">删除</a-popconfirm> -->
              </div>
            </div>
          </template>
        </a-table>
      </div>
    </a-card>
  </div>
</template>
  
<script>
import moment from 'moment';
import 'moment/locale/zh-cn';
import { request } from '@/utils/request'
const columns = [
  {
    title: '联系人',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' },
    align: 'center',
    width: 150,
  },
  {
    title: '联系人角色',
    dataIndex: 'roleName',
    scopedSlots: { customRender: 'roleName' },
    align: 'center',
    width: 150

  },
  {
    title: '手机号',
    dataIndex: 'phone',
    ellipsis: true,
    align: 'center',
    scopedSlots: { customRender: 'phone' },
  },
  {
    title: '座机号',
    dataIndex: 'landline',
    ellipsis: true,
    align: 'center',
    scopedSlots: { customRender: 'landline' },
  },
  {
    title: '性别',
    dataIndex: 'sexName',
    align: 'center',
    width: 100
  },
  {
    title: '生日',
    dataIndex: 'birthday',
    scopedSlots: { customRender: 'birthday' },
    align: 'center',
    width: 100,
  },
  {
    title: '邮箱',
    dataIndex: 'mailbox',
    ellipsis: true,
    align: 'center',

    width: 100,
    scopedSlots: { customRender: 'mailbox' },
  },
  {
    title: '微信号',
    dataIndex: 'wechat',
    ellipsis: true,
    align: 'center',
    scopedSlots: { customRender: 'wechat' },
  },
  {
    title: 'QQ',
    dataIndex: 'qq',
    ellipsis: true,
    align: 'center',
    scopedSlots: { customRender: 'qq' },
  },
  {
    title: '其他联系方式',
    dataIndex: 'othen',
    ellipsis: true,
    align: 'center',
    scopedSlots: { customRender: 'othen' },
  },
  {
    title: '操作',
    scopedSlots: { customRender: 'action' },
    fixed: 'right',
    align: 'center',
    width: 160
  }
]
export default {
  name: 'QueryList',
  inject: ['reload'],
  props: {
    isBackShow: {
      type: Boolean,
      default: true
    },
    //是否显示新增按钮
    isEdit: {
      type: Boolean,
      default: false
    },
    selectId:{
      type:String,
      defalut:''
    },
    isShowAddress:{
      type:Boolean,
      default:true
    }
  },
  data() {
    return {
      iptmd: 4,
      iptsm: 22,
      moment,
      customerData: [],
      userData: [],
      advanced: true,
      columns: columns,
      dataSource: [],
      tabLoading: false,
      selectedRows: [],
      total: 0,
      sfaContactsList: [],
      roleList: ["未知", "总负责人", "重要决策人", "办事人员", "其他"],
      sexList: ["男", "女", "未知"],
      // 收货地址列表
      sfaContactAddressList: [{
        "consignee": "",
        "mobile": "",
        "provinceCode": "110000",
        "provinceName": "北京市",
        "cityCode": "110100",
        "cityName": "北京市",
        "countyCode": "",
        "countyName": "",
        "detailedAddress": ""
      }],
      customerManagerIdList: [], //客户经理id
      // 筛选
      queryData: {
        page: 0,
        size: 10,
        name: '',//方案名称
        minProductBudget: '',
        maxProductBudget: '',//产品预算
        minProductPrice: '', //产品价格
        maxProductPrice: '',
        customerIdList: [],//选择客户
        dateMax: '',
        dateMin: '',
      },
      currentPage: 1,
      // 创建表单
      form: {
        "customerId": "",
        "headingType": '',
        "contactsType": '',
        "contactsHeading": "",
        "dutyParagraph": "",
        "bank": "",
        "bankAccount": "",
        "address": "",
        "phone": ""
      },
      // 修改表单
      editObj: {},
      prov1: [],
      prov2: [],
      prov3: [],
      // 新建方案
      visible: false,
      confirmLoading: false,
      // 修改方案
      EditVisible: false,
      // 新建方案弹窗
      labelCol: { span: 8 },
      wrapperCol: { span: 24 },
      rules: {
        name: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        role: [{ required: true, message: '请选择角色', trigger: 'change' }],
        customerManagerIdList: [{ required: true, message: '请选择客户经理', trigger: 'change' }],
        sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
      },

      phone: [{ contactInformation: "", type: '1' }],
      wechat: [{ contactInformation: "", type: '4' }],
      zuoji: [{ contactInformation: "", type: '2' }],
      users: [],
      prov: [],
      city: [],
      area: [],
    }
  },
  mounted() {
    this.getCity();
    this.getData()
    //  客户经理 
    request(process.env.VUE_APP_API_BASE_URL + 'sfa/users/list', 'post').then(res => {
      this.users = res.data.data;
    })
  },
  watch: {
        selectId: {
        immediate: true, // 立即执行一次
        handler() {
            this.getData()
        }
    }
  },
  computed: {
    userPermissions() {
      return this.$store.state.account.user.authorityList;
    }
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    hasPermission(permission) {
      return this.userPermissions.includes(permission);
    },
    addIpt(i) {
      if (i == 0) {
        this.phone.push({ contactInformation: "", type: "1" });
      }
      if (i == 1) {
        this.wechat.push({ contactInformation: "", type: "4" });
      }
      if (i == 2) {
        this.zuoji.push({ contactInformation: "", type: "2" });
      }
    },
    delIpt(type, i) {
      if (type == 0) {
        this.phone.splice(i, 1)
      } else if (type == 1) {
        this.wechat.splice(i, 1)
      } else {
        this.zuoji.splice(i, 1)
      }
    },
    addPhone() {
      this.zuoji.push({ contactInformation: "", type: "1" });
    },
    delPhone(i) {
      if(this.zuoji.length == 1) {
        this.$message.error('不可全部删除！')
      } else {
        this.zuoji.splice(i, 1)
      }
      
    },
    handlePageChange(page) {
      this.queryData.page = page - 1;
      this.currentPage = page;
      this.getData()
    },
    // 收货地址
    addressChange(id) {
      this.$router.push({
        path: '/sfa_customer/ContactAddress',
        query: { id: id }
      })
    },

    handleEditCanel() {
      this.EditVisible = false
    },

    // 添加地址
    addAddress(type, i) {
      if (type == 3 || type == 4) {
        if (type == 3) {
          this.customerAddressList.push({})
        } else {
          this.customerAddressList.splice(i, 1)
        }
        return false;
      }
      if (type == 0) {
        this.sfaContactAddressList.push({})
      } else {
        this.sfaContactAddressList.splice(i, 1)
      }
    },
    getCity() {
        request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/0', 'get').then(res => {
          if (res.data.code == 200) {
            this.prov = res.data.data;
            this.gsAddress = res.data.data;
          } else {
            this.$message.error(res.data.message)
          }
        })
        // 市
        request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/110000', 'get').then(res => {
          if (res.data.code == 200) {
            this.city = res.data.data;
          } else {
            this.$message.error(res.data.message);
          }
        })
        // 区
        request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/110100', 'get').then(res => {
          if (res.data.code == 200) {
            this.area = res.data.data;
          } else {
            this.$message.error(res.data.message);
          }
        })
      },
    provChange(id, index) {
      const selProv = this.prov.find(item => item.id === id);
      this.sfaContactAddressList[index].provinceCode = selProv.id;
      this.sfaContactAddressList[index].provinceName = selProv.name;
      request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/' + id, 'get').then(res => {
        if (res.data.code == 200) {
          this.city = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      })
    },
    cityChange(id, index) {
      const selProv = this.city.find(item => item.id === id);
      this.sfaContactAddressList[index].cityCode = selProv.id;
      this.sfaContactAddressList[index].cityName = selProv.name;
      request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/' + id, 'get').then(res => {
        if (res.data.code == 200) {
          this.area = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      })
    },
    areaChange(id, index) {
      const selProv = this.area.find(item => item.id === id);
      this.sfaContactAddressList[index].countyCode = selProv.id;
      this.sfaContactAddressList[index].countyName = selProv.name;
    },
    provChange1(id, index) {
      const selProv = this.prov.find(item => item.id === id);
      this.editObj.sfaContactAddressList[index].provinceCode = selProv.id;
      this.editObj.sfaContactAddressList[index].provinceName = selProv.name;
      request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/' + id, 'get').then(res => {
        if (res.data.code == 200) {
          this.city = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      })
    },
    cityChange1(id, index) {
      const selProv = this.city.find(item => item.id === id);
      this.editObj.sfaContactAddressList[index].cityCode = selProv.id;
      this.editObj.sfaContactAddressList[index].cityName = selProv.name;
      request(process.env.VUE_APP_API_BASE_URL + 'api/adcode/list/' + id, 'get').then(res => {
        if (res.data.code == 200) {
          this.area = res.data.data;
        } else {
          this.$message.error(res.data.message);
        }
      })
    },
    areaChange1(id, index) {
      const selProv = this.area.find(item => item.id === id);
      this.editObj.sfaContactAddressList[index].countyCode = selProv.id;
      this.editObj.sfaContactAddressList[index].countyName = selProv.name;
    },

    phoneChange(e, index) {
      this.phone[index].type = ''
      this.phone[index].type = e.key;
    },
    weChatChange(e, index) {
      this.wechat[index].type = ''
      this.wechat[index].type = e.key;
    },
    zuojiChange(e, index) {
      this.zuoji[index].type = ''
      this.zuoji[index].type = e.key;
    },

    phoneTypeChange(e,index) {
      this.zuoji[index].type = e.key;
      console.log(this.zuoji,'this.zuoji')
    },
    // 格式化年月日
    formatDate(timestamp) {
      const date = new Date(timestamp);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      let result = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`
      return result
    },
    addBtn() {
      this.visible = true;
      this.form = {}
      this.phone = [{ contactInformation: "", type: '1' }]
      this.wechat = [{ contactInformation: "", type: '4' }]
      this.zuoji = [{ contactInformation: "", type: '2' }]
      this.sfaContactAddressList = [{
        "consignee": "",
        "mobile": "",
        "provinceCode": "110000",
          "provinceName": "北京市",
          "cityCode": "110100",
          "cityName": "北京市",
        "countyCode": "",
        "countyName": "",
        "detailedAddress": ""
      }]
    },
    // 编辑
    editChange(e) {
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/contacts/' + e.id, 'get').then(res => {
        this.editObj = res.data.data
        this.zuoji = []
        if (this.editObj.birthday) {
          this.editObj.birthday = this.formatDate(this.editObj.birthday)
        }
        this.editObj.sfaContactInformationList.forEach(item => {
          this.zuoji.push({ type: item.type, contactInformation: item.contactInformation, id: item.id,isNoEdit:true })
        })
        this.EditVisible = true;
       
      })
    },
    formatPhone(phone, type, id) {
      if (phone) {
        const phoneNumbers = phone.split(",");
        const formattedPhoneNumbers = phoneNumbers.map(number => {
          return { type: type, contactInformation: number.trim(), id: id };
        });
        return formattedPhoneNumbers;
      }
    },
    handleEdit(e) {
      e.preventDefault()
      console.log(this.zuoji,'this.zuoji22')
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if(this.zuoji.length > 0) {
            for (let i = 0; i < this.zuoji.length; i++) {
              if(this.zuoji[i].type == 2) {
                  if (this.zuoji[i].contactInformation != "" && this.zuoji[i].contactInformation.length < 10 ) {
                    this.$message.error('请输入完整的座机号，包括区号！');
                    return; // 当座机号不满11位时立即返回提示信息
                  }
                  
                  if (this.hasNonNumeric(this.zuoji[i].contactInformation)) {
                    this.$message.error('座机号仅限数字！');
                    return; // 当发现非数字字符时立即停止循环
                  }
                }
              }
            }
          // 过滤空联系号码框
          let mationList = this.zuoji;
          const result = mationList.some(obj => {
            return (obj.type === 1 || obj.type === 2 || obj.type === 4) && obj.contactInformation !== '';
          });
          if(!result) {
            this.$message.error('手机号或座机号或微信不可全部为空！')
            return false;
          }
          console.log(result); // true
          // let arr = this.phone.concat(this.wechat, this.zuoji);
          // arr.forEach(it => {
          //   if (it) {
          //     mationList.push(it)
          //   }
          // })
          this.editObj.sfaContactInformationList = mationList
          this.editObj.sfaContactAddressList.addressFull = ''
          this.editObj.customerId = this.selectId
          delete this.editObj.roleName;
          delete this.editObj.phone;
          delete this.editObj.createTime;
          delete this.editObj.sexName;

          // let a = {
          //   "sfaContactsList": [{
          //     id: this.editObj.id,
          //     "sfaContactInformationList": mationList,
          //   }]
          // }
          request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/check', 'post', {
            "sfaContactsList": [{
              id: this.editObj.id,
              "sfaContactInformationList": mationList,
            }
            ]
          }).then(res => {
            if (res.data.data) {
              this.$message.warning(res.data.data)
              return false;
            } else {
              request(process.env.VUE_APP_API_BASE_URL + 'sfa/contacts/update', 'post', this.editObj).then(res => {
                if (res.data.code == 200) {
                  this.$message.success('编辑成功！')

                  this.EditVisible = false;
                  this.getData()
                  this.reload()
                } else {
                  this.$message.error(res.data.message)
                }
              })
            }
          })

        } else {
          return false;
        }
      });
    },
    hasNonNumeric(inputString) {
        return /\D/.test(inputString);
      },
    handleOk(e) {
      e.preventDefault()
      console.log(this.zuoji,'this.zuoji')
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if(this.zuoji.length > 0) {
            for (let i = 0; i < this.zuoji.length; i++) {
              if(this.zuoji[i].type == 2) {
                  if (this.zuoji[i].contactInformation != "" && this.zuoji[i].contactInformation.length < 10 ) {
                    this.$message.error('请输入完整的座机号，包括区号！');
                    return; // 当座机号不满11位时立即返回提示信息
                  }
                  
                  if (this.hasNonNumeric(this.zuoji[i].contactInformation)) {
                    this.$message.error('座机号仅限数字！');
                    return; // 当发现非数字字符时立即停止循环
                  }
                }
              }
            }
          if (this.phone[0].contactInformation == "" && this.wechat[0].contactInformation == "" && this.zuoji[0].contactInformation == "") {
            this.$message.error('请补全联系方式')
            return false;
          }
          // 过滤空地址
          this.sfaContactAddressList = this.sfaContactAddressList.map(obj => {
            for (let key in obj) {
              if (obj[key] === '') {
                delete obj[key];
              }
            }
            return obj;
          }).filter(obj => Object.values(obj).some(value => value !== ''));
          let mationList = this.phone.concat(this.wechat, this.zuoji);
          this.form.customerId = this.selectId
          this.form.sfaContactAddressList = this.sfaContactAddressList;
          this.form.sfaContactInformationList = mationList;
          request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/check', 'post', {
            "sfaContactsList": [{
              "sfaContactInformationList": mationList,
            }]
          }).then(res => {
            if (res.data.data) {
              this.$message.warning(res.data.data)
              return false;
            } else {
              request(process.env.VUE_APP_API_BASE_URL + 'sfa/contacts/save/' + this.selectId, 'post', this.form).then(res => {
                if (res.data.code == 200) {
                  this.$message.success('添加成功！')
                  this.visible = false;
                  this.form = {}
                  this.getData()
                  this.reload()
                } else {
                  this.$message.error(res.data.message)
                }
              })
            }
          })

        } else {
          return false;
        }
      });
    },
    resetQuery() {
      this.queryData = {
        page: 0,
        size: 10,
        name: '',
        minProductBudget: '',
        maxProductBudget: '',//产品预算
        minProductPrice: '', //产品价格
        maxProductPrice: '',
        customerIdList: [],//选择客户
        dateMax: '',
        dateMin: '',
        // staffId:'',
        // dealStatus: '',//成交状态
        // type: '',//
      }
      this.getData()
    },

    // 获取列表 
    getData() {
      this.tabLoading = true;
      console.log(this.selectId,'id')
      if(!this.selectId) return false;
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/contacts/list/' + this.selectId, 'get').then(res => {
        let data = res.data.data;
        this.tabLoading = false;
        if (res.data.code == 200) {
          this.dataSource = data;
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    // 是否确认删除
    delConfirm(e) {
      this.$message.loading();
      request(process.env.VUE_APP_API_BASE_URL + 'sfa/contacts/' + e.id, 'delete').then(res => {
        if (res.data.code == 200) {
          this.$message.success('删除成功')
          this.EditVisible = false;
          this.getData()
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    birthdayChange(date, dateString) {
      this.form.birthday = dateString
    },
    birthdayEdit(date, dateString) {
      this.editObj.birthday = dateString
    },
  }
}
</script>
  
<style lang="less" scoped>
.card {
  margin-bottom: 24px;
}

.search {
  // margin-bottom: 54px;
}

.fold {
  width: calc(100% - 216px);
  display: inline-block
}

.operator {
  margin-bottom: 18px;
}

@media screen and (max-width: 900px) {
  .fold {
    width: 100%;
  }
}

::v-deep .page-content {
  padding: 0 !important;
}

.cre-btn {
  width: 100%;
  height: 64px;
  line-height: 64px;
  background: #EEECFE;
  border-radius: 4px;
  border: 1px dashed #5542F6;
  cursor: pointer;
  font-size: 16px;
  font-family: PingFangHK-Regular, PingFangHK;
  font-weight: 400;
  color: #5542F6;
}

.btn-box button {
  width: 106px;
  height: 36px;
  margin-left: 10px;
}



.page-box {
  display: flex;
  justify-content: right;
  padding-right: 46px;
  margin-top: 10px;
}



@media (max-width: 1800px) {
  ::v-deep .ant-card-body {
    padding: 12px !important;
    // zoom: .8 !important;
  }

  ::v-deep .ant-col-md-4 {
    // width: 16% !important;
    // margin-right: 20px;
  }
}


@media (max-width: 1600px) {
  ::v-deep .ant-col-md-4 {
    width: 18% !important;
  }
}
</style>
  
  